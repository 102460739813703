import React from 'react';
import Section from "../../../components/ui/Section/Section";
import Layout from '../../../components/Layout';
import { Link } from 'gatsby';
import Illustration from '../../../assets/karriere.svg';
import * as styles from '../job-karriere.module.scss';
import classnames from 'classnames';

export default function buerotalent () {

    return <Layout crumbs={[
        { label: "Home", url: "" }, 
        { label: "Unternehmen", url: "unternehmen" }, 
        { label: "Karriere", url: "jobs-karriere" }, 
        { label: "Kauffrau / Kaufmann für Büromanagement", url: "buerotalent" }
        ]} headerColor="dark" hasWave={false} breadcrumbColor="dark">


        <Section>
            <div className="columns">
                <div className="column is-1 is-hidden-touch"></div>
                <div className={classnames(["column", styles.illustration])}>
                    <img src={Illustration} alt="Karriere, bist du das passende Puzzelteil?" />
                </div>
                <div className="column is-half-desktop">
                    {/* <div className="content">
                        <p>
                            Zur Verstärkung unseres Teams suchen wir ab sofort eine/n zuverlässige/n
                        </p>
                        <h2 className="title">Kauffrau / Kaufmann für Büromanagement (m/w/d) </h2>
                        <h3 className="subtitle"> in Teilzeit</h3>
                            
                        <p>
                            <strong> Du bringst gerne Struktur ins Chaos, liebst es zu organisieren und bringst Ordnung in einen Zahlendschungel? 
                            Dann bist du bei uns genau richtig!</strong>                        
                        </p>
                        <p>
                            Komm in ein dynamisches, kompetentes und motiviertes Team! Wir sind sind HIS Print & Service. Seit nun über 30 Jahren haben wir uns alles Wissen im Bereich der Werbetechnik und Beschriftung, wie Schilder oder Aufkleber in unterschiedlichsten Anwendungen, angeeignet. Unsere Kunden kommen sowohl von der Industrie, als auch vom Handwerk. Sowohl Firmen als auch Privatpersonen werden bei uns kompetent beraten und mit der besten Lösung beliefert. 
                        </p>

                        <p>
                            Bist du bereit für einen bunten Aufgabenmix? Dann bewirb dich!
                        </p>
                        
                        <p>
                            <strong>Deine Aufgaben</strong>
                            <ul>
                                <li>Kundenbetreuung von der Beratung bis zum Druck</li>
                                <li>Angebots- und Auftragsabwicklung (Auftragskalkulation, Qualitätsmanagement)</li>
                                <li>Allgemeine Bürotätigkeiten</li>
                                <li>Pflege der digitalen Kundenkarteien</li>
                                <li>Gestalterische Tätigkeiten: Interne Datenbereitstellung und Datenaufbereitung</li>
                            </ul>
                        </p>
                        <p>
                            <strong>Dein Profil</strong>
                            <ul>
                                <li>Abgeschlossene kaufmännische Berufsausbildung, wie z.B. Bürokaufmann, Industriekaufmann, etc. </li>
                                <li>Hoher Qualitätsanspruch und Sorgfältigkeit zeichnet dich aus </li>
                                <li>Gestalterisches Talent (optional)</li>
                                <li>Engagement und Teamgeist sowie eigenverantwortliches Arbeiten</li>
                                <li>Schnelle Auffassungsgabe</li>
                                <li>Freude im Umgang mit Kunden</li>
                                <li>Führerschein Klasse B (wegen der Anbindung zum Arbeitsplatz)</li>
                            </ul>
                        </p>
                        <p>
                            <strong>Wir bieten</strong>
                            <ul>
                                <li>Teilzeit, 16-24 Stunden</li>
                                <li>Interessante und abwechslungsreiche Aufgaben</li>
                                <li>Ein ausgezeichnetes Betriebsklima mit Off-Site Events</li>
                                <li>Gestalterischen Freiraum und Möglichkeit zur Mitgestaltung (Zusammenarbeit mit unserer Mediengestalterin)</li>
                                <li>30 Tage Urlaub</li>
                            </ul>
                        </p>

                    
                        <p>
                            Schicke uns jetzt eine Bewerbung an <a href="mailto:bewerbung@hisprint.de">bewerbung@hisprint.de</a>
                        </p>

                        <p>
                            <h3>Lerne uns schon mal genauer kennen:</h3>
                            <ul>
                                <li><Link to="/produkte">Unsere alltäglichen Produkte</Link></li>
                                <li><Link to="/fallbeispiele">Fallbeispiele von Einzelprojekten</Link></li>
                                <li><Link to="/unternehmen">Lerne unser Team jetzt schon kennen</Link></li>
                            </ul>
                        </p>

                    </div> */}
                    <div className="content">

                        <h2 className="title">Zur Zeit suchen wir keine Stelle als Kauffrau / Kaufmann für Büromanagement (m/w/d) </h2>
                    </div>
                    
                    
                </div>

            </div>
        </Section>
    </Layout>
}
